import { isEmptyObject } from '@oracle-cx-commerce/utils/generic';

export const returnObjectIfNotEmpty = object => (!isEmptyObject(object) ? object : undefined);

export const isObjectWithNoValues = obj => !Object.values(obj).find(val => val !== null && val !== undefined);

export const isTrueObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

export const areTwoObjectsIdentical = (obj1 = {}, obj2 = {}) => {
  let areObjectsIndentical = true;

  Object.keys(obj1).forEach(key => {
    if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
      areObjectsIndentical = false;
    }
  });
  Object.keys(obj2).forEach(key => {
    if (!obj1.hasOwnProperty(key) || obj2[key] !== obj1[key]) {
      areObjectsIndentical = false;
    }
  });
  return areObjectsIndentical;
};

/**
 * Retrieves the value from an object based on a given property path.
 *
 * @param {Object} obj - The object to retrieve the value from.
 * @param {string|Array} path - The property path as a string or array.
 * @param {*} [def] - The default value to return if the path is not found.
 * @returns {*} - The value at the specified path or the default value.
 */
export const getFromObjByPropPath = (obj, path, def) => {
  if (!obj) {
    return def;
  }

  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  const stringToPath = pathString => {
    if (typeof pathString !== 'string') {
      return pathString;
    }

    const output = [];

    pathString.split('.').forEach(item => {
      item.split(/\[([^}]+)\]/g).forEach(key => {
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };

  const pathArr = stringToPath(path);

  if (!Array.isArray(pathArr) || pathArr.length === 0) {
    return def || obj;
  }

  let current = obj;

  for (const key of pathArr) {
    if (!current[key]) {
      return def;
    }

    current = current[key];
  }

  return current;
};
